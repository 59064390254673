.layout-relative {
  position: relative;
  width: 100% !important;
  padding-left: 30px;
  padding-right: 30px;

  @media (min-width: 65.5rem) {
    padding-right: unset;
  }
}

.layout-menu-absolute {
  position: absolute;
  top: 0rem;
  right: 0rem;
}
