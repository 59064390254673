.plan-details-main {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.5rem;

  @media (min-width: 65.5rem) {
    align-items: center;
    justify-content: space-between;
    grid-template-columns: repeat(2, 1fr);
  }
}

.package-plan {
  border-bottom: 2px solid #dd0202;

  display: flex;
  flex-direction: column;
  padding-bottom: 1.25rem !important;

  .package-name {
    font-size: 1.25rem;
    gap: 1.25rem;

    @media (min-width: 65.5rem) {
      font-size: 1.25rem;
    }
  }

  @media (min-width: 65.5rem) {
    padding-bottom: unset !important;
    gap: 1.375rem;
    border-bottom: none;
    border-right: 2px solid #dd0202;
  }

  .package-services {
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    @media (min-width: 65.5rem) {
      grid-template-columns: repeat(1, 1fr);
      gap: 1rem;
      align-items: center;
    }
  }
}

.payment-details-info {
  display: flex;
  flex-direction: column;

  @media (min-width: 65.5rem) {
    padding: 0rem 1rem;
  }
}

.payment-details {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  align-items: center;
}

.px-1 {
  @media (min-width: 65.5rem) {
    padding: 0 1rem;
  }
}

.h5 {
  font-size: 1rem;
  font-weight: 600;
}

.p {
  font-size: 1rem;
  font-weight: 500;
}

.greeting-msg {
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 1.25rem;
    font-weight: 600;

    @media (min-width: 65.5rem) {
      font-size: 1.125rem;
    }
  }

  p {
    font-size: 1rem !important;
  }
}

.subscription-main {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media (min-width: 65.5rem) {
    gap: 3.5rem;
  }
}

.unsubscribed-message {
  padding: 1rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    font-size: 1.25rem;
    font-weight: bold;
    border-radius: 0.625rem !important;
  }
}