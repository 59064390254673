@use "../../dashlite_variables" as *;

.input-model {
  display: flex;
  .input {
    width: 100%;
    height: 100%;
    input {
      height: 100%;
      position: relative;
      font-size: 1rem;
      border-radius: 0.225rem;
      transition: 0.3s ease-in-out all;
      background-color: transparent;
      &:active,
      &:focus {
        border: 1px solid $accent-color;
        outline: none;
        box-shadow: 0px 1px 0px 2px $accent-color;
      }
      &:disabled {
        background-color: #f5f6fa !important;
        color: #666 !important;
        cursor: not-allowed !important;
      }
    }
  }
  .input.dark {
    input:disabled {
      background-color: #2c3e50 !important;
      color: #95a5a6 !important;
    }
  }
  .eye-icon {
    position: absolute;
    cursor: pointer;
    top: 5.7rem;
    right: 2.5rem;
  }
}
