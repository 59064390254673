.heading-title {
  margin-top: 2.8rem;

  h2 {
    font-size: 1.25rem;
  }

  @media (min-width: 65.5rem) {
    margin-top: unset;

    h2 {
      font-size: 2rem;
    }
  }
}

.subscription-main {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media (min-width: 65.5rem) {
    gap: 3.5rem;
  }
}

.package-details-main {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.5rem;

  @media (min-width: 65.5rem) {
    align-items: center;
    justify-content: space-between;
    grid-template-columns: repeat(2, 1fr);
  }
}