/*!
 * Theme: Green
 * Package: DashLite v@@@version
 * Updated: @@@released
 * Author: Softnio
 * Author URI: http://themeforest.net/user/softnio
**/

///// Included Variable
@import "variable_green";

/// Default Variable (do not change here)
/// If you need, please change from _varilable_{theme}.scss)
$skin_accent: $accent-color;
$skin_accent-100: $accent-100;
$skin_accent_light: $accent-light;
$skin_accent_dark: $accent-dark;

$skin_body_bg: $body-bg;

$skin_light: $light;
$skin_lighter: $lighter;

$skin_base_color: $base-color;
$skin_base_text: $base-text;
$skin_base_light: $base-light;

$skin_header_bg: $header-bg-color;
$skin_header_bdr: $header-border-bottom;

$skin_header_bg_dark: $header-bg-dark-color;
$skin_header_bg_theme: $header-bg-theme-color;

$skin_sidebar_bg_dark: $sidebar-bg-dark-color;
$skin_sidebar_bdr_dark: $sidebar-border-dark-color;

$skin_sidebar_bg_theme: $sidebar-bg-theme-color;
$skin_sidebar_bdr_theme: $sidebar-border-theme-color;

$skin_menu_color_dark: lighten($base-light, 2%);
$skin_menu_color_theme: darken($skin_accent_light, 2%);
$skin_menu_active_color: $skin_accent;

/// SKIN OVERRIDER
////////////////////////

///// Sidebar Style
.nk-sidebar {
  &.is-dark {
    background: $skin_sidebar_bg_dark;
    border-right-color: $skin_sidebar_bdr_dark;
  }
  &.is-theme {
    background: $skin_sidebar_bg_theme;
    border-right-color: $skin_sidebar_bdr_theme;
  }
  &-head {
    .is-dark & {
      border-color: $skin_sidebar_bdr_dark;
    }
    .is-theme & {
      border-color: $skin_sidebar_bdr_theme;
    }
  }
  &.is-dark,
  &.is-theme {
    .user-balance-alt {
      color: rgba($white, 0.8);
    }
  }
}

///// Header Style
.nk-header {
  background: $skin_header_bg;
  border-bottom-color: $skin_header_bdr;
  &.is-dark:not([class*="bg-"]) {
    background: $skin_header_bg_dark;
  }
  &.is-theme:not([class*="bg-"]) {
    background: $skin_header_bg_theme;
  }
}

///// .is-dark style for sidebar and header
.is-dark {
  .nk-menu-link {
    color: $skin_menu_color_dark;
  }
  .nk-menu-icon {
    color: lighten($skin_menu_color_dark, 8%);
  }
  .nk-menu-badge {
    color: lighten($skin_accent, 20%);
    background: lighten($skin_sidebar_bg_dark, 10%);
  }
  .nk-menu-sub .active > .nk-menu-link,
  .nk-menu-link:hover,
  .active > .nk-menu-link,
  .nk-menu-link:hover .nk-menu-icon,
  .nk-menu-item.active > .nk-menu-link .nk-menu-icon,
  .nk-menu-item.current-menu > .nk-menu-link .nk-menu-icon {
    color: lighten($skin_accent, 10%);
  }

  .nk-news-icon .icon {
    color: $skin_accent;
  }
  .nk-news-text .icon {
    color: $skin_base_light;
  }
  .nk-news-text p span {
    color: rgba($skin_base_light, 0.9);
  }
  .user-name,
  .nk-news-text p {
    color: lighten($skin_base_light, 5%);
  }
  .nk-quick-nav-icon {
    color: lighten($skin_base_light, 10%);
  }
}

///// .is-theme style  for sidebar and header
.is-theme {
  .nk-menu-link {
    color: $skin_menu_color_theme;
  }
  .nk-menu-icon {
    color: lighten($skin_menu_color_theme, 8%);
  }
  .nk-menu-badge {
    color: lighten($skin_accent, 30%);
    background: lighten($skin_sidebar_bg_theme, 10%);
  }
  .nk-news-icon .icon {
    color: lighten($skin_accent, 15%);
  }
  .nk-news-text .icon,
  .nk-quick-nav-icon,
  .overline-title {
    color: $skin_accent_light;
  }
  .nk-news-text p span,
  .nk-menu-heading .overline-title {
    color: rgba($skin_accent_light, 0.8);
  }
  .user-name,
  .nk-news-text p {
    color: lighten($skin_accent_light, 5%);
  }
}

/// Colors Overrides
//////////////////////////////
.card-tools-nav li a:before,
.nav-tabs .nav-link:after,
.progress-bar,
.dot-primary,
.alert-fill.alert-primary,
.bg-primary,
.icon-circle,
.noUi-connect,
.nk-msg-menu-item a:after,
.ui-shady .nk-msg-item.active:after,
.ui-shady .nk-msg-item.current:after,
.ui-softy .nk-msg-item.active:after,
.ui-softy .nk-msg-item.current:after {
  background: $skin_accent;
}
.card-bordered.is-dark {
  border-color: $skin_accent_dark;
}
.card.is-dark {
  background: $skin_accent_dark;
}
.user-avatar,
[class^="user-avatar"]:not([class*="-group"]) {
  background: lighten($skin_accent, 10%);
}
.nk-news-item:hover .nk-news-text .icon {
  color: lighten($skin_accent, 10%);
}

.nk-menu-link:hover,
.nk-menu-link:hover .nk-menu-icon,
.nk-menu-item.active > .nk-menu-link .nk-menu-icon,
.nk-menu-item.current-menu > .nk-menu-link .nk-menu-icon,
.nk-menu-link:hover .count,
.nk-menu-sub .nk-menu-link:hover,
.nk-menu-sub .active > .nk-menu-link,
.nk-menu-sm .nk-menu-link:hover,
.nk-menu-main .nk-menu-link:hover,
.nk-menu-main .nk-menu-link:focus,
.nk-menu-main .nk-menu-item.active > .nk-menu-link,
.nk-menu-main .nk-menu-item.current-menu > .nk-menu-link,
.nk-menu-main .nk-menu-item:hover > .nk-menu-link,
.is-light .nk-menu-link:hover,
.is-light .active > .nk-menu-link,
.active > .nk-menu-link,
.active > .nk-menu-link .count,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.active .nav-link,
.nk-menu-footer .nk-menu-icon,
.nk-menu-footer .nk-menu-link:hover,
.nk-footer-copyright a:hover .page-link:hover,
.list-plain a:hover,
.link-check li a:hover,
.link-list a:hover,
.link-list-opt a:hover,
.link-list-plain a:hover,
.link-list-menu li.active > a,
.link-list-menu a.active,
.link-list-menu a:hover,
.link-list-menu li.active > a .icon,
.link-list-menu a.active .icon,
.link-list-menu a:hover .icon,
.link-list-menu li.active > a:after,
.link-list-menu a.active:after,
.link-list-menu a:hover:after,
.list-checked li:before,
.list-step li.list-step-current:before,
.accordion-s2 .accordion-head .title,
.accordion-s3 .accordion-head .title,
.bg-outline-primary,
.badge-dim.bg-primary,
.badge-dot.bg-primary,
.badge-dim.bg-outline-primary,
.alert-primary,
.form-clip,
.form-text-hint,
.search-submit:hover,
.nk-news-icon .icon,
.attach-item .icon,
.attach-download:hover span,
.nk-reply-meta-info .whom,
.nk-msg-tags li > span .icon,
.nk-msg-menu-item a:hover,
.nk-msg-menu-item.active a,
.user-balance,
.user-avatar[class*="-primary-dim"],
.nk-order-ovwg-data.sell .amount,
.nk-order-ovwg-data.sell .title .icon,
.nk-wg-action-content p strong,
a,
.dropzone .dz-message-text span,
.nk-switch-icon.active,
.link-list-plain a .icon,
.chat-upload-option a,
.is-unread .chat-context .status,
.add-opt:hover .sub-text,
.add-opt:hover .icon,
.icon[class*="bg-primary-dim"] {
  color: $skin_accent;
}
a:hover {
  color: darken($skin_accent, 8%);
}
.text-primary,
.link-primary {
  color: $skin_accent !important;
}
.link-primary:hover,
a.text-primary:hover,
a.text-primary:focus,
.chat-upload-option a:hover {
  color: darken($skin_accent, 8%) !important;
}
.border-primary,
.nk-kycfm-control:checked ~ .nk-kycfm-label {
  border-color: $skin_accent !important;
}
.bg-lighter {
  background-color: $skin_lighter !important;
}
.bg-primary {
  background-color: $skin_accent !important;
}
a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: darken($skin_accent, 10%) !important;
}
.bg-primary-dim,
.dual-listbox .dual-listbox__item:active,
.dual-listbox .dual-listbox__item.dual-listbox__item--selected {
  background-color: mix($skin_accent, $white, 10%) !important;
}
.text-primary-dim {
  color: mix($skin_accent, $white, 10%) !important;
}
.alert-primary,
.badge-dim.bg-outline-primary {
  background-color: mix($skin_accent, $white, 10%);
  border-color: mix($skin_accent, $white, 40%);
}
.alert-pro.alert-primary,
.bg-primary,
.form-control:focus,
.form-control.focus,
.custom-file-input:focus ~ .custom-file-label,
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before,
.select2-container--default .select2-selection--single:focus,
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: $skin_accent;
}
.nk-order-ovwg-data.sell,
.bg-outline-primary {
  border-color: mix($skin_accent, $white, 40%);
}
.dropdown-menu-s1 {
  border-top-color: $skin_accent;
}
.page-item.active .page-link,
.custom-control-input:checked ~ .custom-control-label::before,
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  background-color: $skin_accent;
  border-color: $skin_accent;
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before,
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before,
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: mix($skin_accent, $white, 60%);
}
.badge-dim.bg-primary {
  background-color: mix($skin_accent, $white, 10%);
  border-color: mix($skin_accent, $white, 10%);
}
.bg-primary.badge-dot {
  background: transparent;
}
.nk-error-head {
  background: -webkit-linear-gradient($skin_accent, lighten($skin_accent_dark, 10%));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.nav-switch .nav-link.active {
  background: $skin_accent;
  color: $white;
}
.icon-avatar {
  color: $skin_accent;
  background-color: mix($skin_accent, $white, 15%);
}
.is-theme .nk-quick-nav-icon:before {
  background-color: darken($skin_accent_dark, 10%);
}

// Buttons
.btn-primary {
  background: linear-gradient(23deg, rgb(0 0 0) 0%, rgb(33 99 6) 35%, rgb(69 231 66) 100%);
  border-color: $skin_accent;

  &:hover {
    background: linear-gradient(23deg, rgb(82 224 93) 0%, rgb(54 186 0) 35%, rgb(4 28 4) 100%);
    border-color: darken($skin_accent, 6%);
  }

  &:focus {
    background: linear-gradient(23deg, rgb(82 224 93) 0%, rgb(54 186 0) 35%, rgb(4 28 4) 100%);
    border-color: darken($skin_accent, 6%);
  }

  &.btn-dim {
    color: $skin_accent;
    background-color: mix($skin_accent, $white, 11%);
    border-color: mix($skin_accent, $white, 11%);
  }
}

.btn-dim.btn-outline-primary {
  color: $skin_accent;
  background-color: mix($skin_accent, $white, 11%);
  border-color: mix($skin_accent, $white, 40%);
  &.btn-white {
    background-color: $white;
  }
}
.btn-outline-primary {
  border-color: $skin_accent;
  color: $skin_accent;
  &:hover {
    background-color: $skin_accent;
    border-color: $skin_accent;
  }
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background-color: darken($skin_accent, 3%);
  border-color: darken($skin_accent, 4%);
}

.btn-primary:focus,
.btn-outline-primary:focus,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary.btn-dim:focus,
.btn-primary.btn-dim:not(:disabled):not(.disabled):active,
.btn-primary.btn-dim:not(:disabled):not(.disabled):active:focus,
.btn-dim.btn-outline-primary:focus,
.btn-dim.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-dim.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba($skin_accent, 0.2);
}
.btn-outline-light:focus,
.btn-outline-light.focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba($skin_base_light, 0.1);
}
.btn-trigger:focus {
  box-shadow: none;
}
.form-control:focus,
.form-control.focus,
.custom-control-input:focus ~ .custom-control-label::before,
.custom-file-input:focus ~ .custom-file-label,
.select2-container--default .select2-selection--single:focus,
.select2-container--default.select2-container--focus .select2-selection--multiple {
  box-shadow: 0 0 0 3px rgba($skin_accent, 0.1);
}
.form-control-simple:focus {
  box-shadow: none;
}
.btn-primary.btn-dim:not(:disabled):not(.disabled):hover,
.btn-dim.btn-outline-primary:not(:disabled):not(.disabled):hover,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle,
.btn-primary.disabled,
.btn-primary:disabled,
.invest-amount-control:checked ~ .invest-amount-label {
  background-color: $skin_accent;
  border-color: $skin_accent;
}

.form-focus-none:focus {
  border-color: transparent;
  box-shadow: none;
}

@media (min-width: 992px) {
  .nk-menu-main > li > .nk-menu-link:before {
    background: $skin_accent;
  }
  .is-theme .nk-menu-main > li > .nk-menu-link {
    color: $skin_accent_light;
    &.nk-menu-toggle:after {
      color: rgba($skin_accent_light, 0.7);
    }
  }
}

// DatePicker
.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
  background-color: mix($skin_accent, $white, 15%);
  color: $skin_accent;
}
.datepicker table tr td.today:hover,
.datepicker table tr td.today:hover:hover,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today.disabled:hover:hover,
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today:hover.disabled,
.datepicker table tr td.today.disabled.disabled,
.datepicker table tr td.today.disabled:hover.disabled,
.datepicker table tr td.today[disabled],
.datepicker table tr td.today:hover[disabled],
.datepicker table tr td.today.disabled[disabled],
.datepicker table tr td.today.disabled:hover[disabled] {
  background-color: darken($skin_accent_light, 15%);
}
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td span:hover,
.datepicker table tr td span.focused {
  background-color: $skin_accent;
}
.code-tag {
  color: blue;
}
.alert-pro {
  background: $white;
  color: $skin_base_text;
}
.nk-menu-badge {
  color: mix($skin_accent, $white, 70%);
  background-color: mix($skin_accent, $white, 10%);
}
.is-theme .nk-sidebar-footer,
.nk-apps-sidebar.is-theme,
.is-theme .nk-sidebar-profile-fixed {
  background: $skin_sidebar_bg_theme;
  border-color: $skin_sidebar_bdr_theme;
}
.is-theme .nk-menu-footer .nk-menu-link,
.is-theme .sub-text,
.is-theme .lead-text span {
  color: $skin_accent_light;
}
.apps-menu .nk-menu-link:before {
  background: $skin_accent;
}

.nav-switch-s2 .nav-link:hover,
.nav-switch-s2 .nav-link:focus {
  color: $skin_base_text;
}
.nav-switch-s2 .nav-link.active {
  color: $skin_base_color;
}

//io-v130 @inbox
.active .nk-ibx-menu-text,
.active .nk-ibx-menu-item .icon,
.nk-reply-form-nav li a:hover,
.nk-reply-form-input .toggle-opt:hover {
  color: $skin_accent;
}
.nk-ibx-menu li.active {
  background: mix($skin_accent, $white, 8%);
}
//@File Manager
.nk-fmg-menu li.active {
  background: mix($skin_accent, $white, 8%);
}
.nk-file-name .asterisk .icon,
.nk-file-name-text a.title:hover,
.nk-file-link:hover .title,
.active .nk-fmg-menu-item .icon,
.active .nk-fmg-menu-text {
  color: $skin_accent;
}
.nk-files-view-list .nk-file.selected {
  background-color: $skin_accent-100;
}

//@Chats
.chat.is-me .chat-msg {
  background-color: $skin_accent;
}

//@jKanban
.kanban-add-task {
  color: $skin_accent;
  &:hover {
    background: $skin_accent;
    border-color: $skin_accent;
  }
}

// wizard
.actions ul li a {
  border-color: $skin_accent;
  background: $skin_accent;
}
.nk-wizard-simple .steps ul li.done h5,
.nk-wizard-simple .steps ul li.done .number,
.nk-wizard-simple .steps ul li.current h5,
.nk-wizard-simple .steps ul li.current .number {
  color: $skin_accent;
}
.nk-wizard-simple .steps ul li:after {
  background: $skin_accent;
}

// @new 050421
.invoice-contact ul .icon,
.invoice-desc .title,
.invoice-bills .table th {
  color: $skin_accent;
}
.product-gallery .slider-nav .slider-item.slick-current .thumb,
.custom-control-pro.no-control .custom-control-input:checked ~ .custom-control-label,
.custom-control-pro.no-control .custom-control-input:not(:disabled):active ~ .custom-control-label,
.custom-control.color-control .custom-control-label:before {
  border-color: $skin_accent !important;
}
