.country-option {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.country-logo {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.selected-logo {
  width: 19px;
  height: 19px;
  margin-right: 5px;
}

.btnFG {
  margin-top: 10px;
  gap: 7px;
  width: 150px;
  height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  border: 1px solid #333;
  color: #4267b2;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.btnFG:hover {
  border: 1px solid #2d79f3;
}
