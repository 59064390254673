@use "../../../assets/scss/dashlite_variables" as *;

.textarea {
  border-radius: 0.225rem;
  height: 8rem !important;
  background: transparent;
  border-color: $border-light-alt;
  padding: 0.625rem 1rem;

  &:hover {
    border-color: $border-dark-alt;
  }

  &:focus {
    border-width: 2px;
    border-color: $field-focus-color;
    outline-color: $field-focus-color;
  }

  &.dark {
    color: $white;
    border-color: $border-dark-alt !important;
    outline: none !important;

    &:hover {
      border-width: 1.5px;
      border-color: $white !important;
    }

    &:focus {
      border-color: $field-focus-color !important;
    }
  }
}
