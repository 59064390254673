@use "../../../../../assets/scss/dashlite_variables" as *;

.phone-input {
  background-color: transparent !important;

  input {
    border: none !important;
    background-color: transparent !important;
    // color: #7f7f7f;

    &:focus,
    &:hover,
    &:active {
      border: none;
      outline: none;
    }
  }

  &.disable-phone-input {
    background-color: #f5f6fa !important;

    select {
      display: none;
      background-color: transparent !important;
      appearance: none;
      -webkit-appearance: none;
    }
  }
}

.two-fact-form {
  display: flex;
  // justify-content: space-between;
  width: 100%;

  @media (min-width: 65.5rem) {
    justify-content: flex-start;
  }
}

.input-with-btn {
  display: flex;
  flex-direction: column !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;

  @media (min-width: 65.5rem) {
    // flex-direction: row !important;
  }
}

// * {
// background: #000 !important;
// color: limegreen !important;
// outline: solid limegreen 1px !important;
// }

.dark-mode {
  input {
    color: white !important;
  }
}

.dark-box {
  background: #0000006e !important;
  color: black !important;
}

.input-model {
  display: flex;
  .input {
    width: 100%;
    height: 100%;
    .phone-input {
      height: 100%;
      position: relative;
      font-size: 1rem;
      border-radius: 0.425rem;
      transition: 0.3s ease-in-out all;
      background-color: transparent;
      outline: none !important;

      &:active,
      &:focus {
        border: 1px solid $accent-color !important;
        box-shadow: 0px 1px 0px 2px $accent-color !important;
      }
      &:disabled {
        background-color: #f5f6fa !important;
        color: #666 !important;
        cursor: not-allowed !important;
      }
    }
  }
  .input.dark {
    input:disabled {
      background-color: #2c3e50 !important;
      color: #95a5a6 !important;
    }
  }
  .eye-icon {
    position: absolute;
    cursor: pointer;
    top: 5.7rem;
    right: 2.5rem;
  }
}
