@use "../../../assets/scss/themes-vars.module" as *;

div {
  &.backdrop {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    z-index: 10000;
    width: 100%;
    height: 100vh;

    backdrop-filter: blur(2px);
    cursor: pointer;

    background: rgba($darkLevel1, 0.2);
    &.dark {
      background: rgba($darkLevel1, 0.5);
    }
  }
}

#image-article {
  &.overlay {
    position: fixed;
    z-index: 12000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    div {
      &.card {
        max-width: 100%;
        width: 31.25rem;
        position: relative;
        padding: 1.875rem 2.5rem;
        box-shadow: 0px 4px 45px rgba(46, 47, 54, 0.47);
      }
    }
    img {
      object-fit: contain;
      width: 35rem;
      height: 35rem;
      border-radius: 0.625rem;
    }
  }
  &.dark {
    .card {
      box-shadow: 0px 4px 45px 0px hsla(240, 4%, 5%, 0.958) !important;
    }
  }
}
