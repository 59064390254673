.package-subscription {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.block-title {
  font-size: small;

  @media (min-width: 65.5rem) {
    font-size: x-large;
  }
}

.subscription-packages-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 0.625rem;
  @media (min-width: 65.5rem) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.subscription-title {

  // background: #ff7b7b;
  // background: -webkit-linear-gradient(114deg, #ff7b7b 0%, #ea6666 50%, #dd0202 100%);
  // background: linear-gradient(114deg, #ff7b7b 0%, #ea6666 50%, #dd0202 100%);

  padding: 1rem;

  color: rgb(245, 249, 252) !important;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.separator {
  margin: 1rem 0;
}

.pricing-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.heading-title {
  margin-top: 2.5rem;

  @media (min-width: 65rem) {
    margin-top: unset;
  }

  p {
    font-size: 1rem;

    @media (min-width: 65.5rem) {
      font-size: 1.4rem;
    }
  }
}
