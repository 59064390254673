.app-card-grid-layout {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;

  @media (min-width: 65.5rem) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.app-card {
  min-height: 20rem;
  display: flex !important;
  justify-content: space-between !important;
}
